import { getFormattedTags } from 'pages/Calls/CallBody/utils';
import { callTagsLimit } from 'pages/Calls/constants';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callsActions } from 'store/calls';
import { CallTagType } from 'store/calls/calls.types';
import { RootState } from 'store/store';
import { getGlobalStyles } from 'styles';
import { translate } from 'localizations';
import { Skeleton } from '@mui/material';
import { NewTagComponent } from 'shared/ui';

import s from '../../styles.module.scss';

interface IOwnProps {
	language: string;
	dealTags: CallTagType[];
	callId: string | undefined;
	hasCallInfo: boolean;
	solo: boolean | undefined;
	expanded: boolean;
	accessRights: boolean | undefined;
	deleteTag: (id: string) => Promise<void>;
}

const DealTags: FC<IOwnProps> = ({
	dealTags,
	language,
	hasCallInfo,
	callId,
	solo,
	expanded,
	deleteTag,
	accessRights,
}) => {
	const dispatch = useDispatch();
	const { setDealTagExpanded } = callsActions;
	const { grayTag } = getGlobalStyles();
	const { trimmedTags, reducedTags } = getFormattedTags(dealTags, callTagsLimit);

	const expandedDealTags = useSelector((state: RootState) => state.calls.expandedDealTags);
	const showAllTags = callId ? expandedDealTags?.[callId] || false : false;

	const tagsToDisplay = showAllTags ? dealTags : trimmedTags;

	const handleShowMoreTags = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (callId) {
			dispatch(setDealTagExpanded({ callId, isExpanded: !showAllTags }));
		}
	};

	const stopPropagation = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};

	const [clickedTagId, setClickedTagId] = useState<string | null>(null);
	const handleClickTagSettings = (id: string | null) => {
		setClickedTagId(id);
	};

	return (
		<div className={s.container} onClick={stopPropagation}>
			{dealTags?.length > 0 ? (
				tagsToDisplay
					.filter((tag) => solo || tag.visible)
					.map((tag: any, index) => (
						<div className={s.tagContainer} key={`${tag.value}-${index}`}>
							<NewTagComponent
								id={tag?.id}
								name={tag.name}
								value={tag?.value}
								theme="lightBlue"
								language={language}
								accessRights={accessRights}
								// settings={(solo || expanded) && true}
								settings={false}
								deleteTag={deleteTag}
								isActiveTag={clickedTagId === tag.id}
								handleClickTag={handleClickTagSettings}
							/>
						</div>
					))
			) : hasCallInfo ? (
				translate('noTags', language)
			) : (
				<div className={s.skeletonContainer}>
					<Skeleton className={s.skeleton} height={20} variant="text" />
					<Skeleton className={`${s.skeleton} ${s.skeletonWithMarginTop}`} height={20} variant="text" />
				</div>
			)}
			{reducedTags?.length > 0 && (
				<button className={grayTag} onClick={handleShowMoreTags}>
					{showAllTags
						? translate('tagHide', language)
						: `${translate('show_more', language)} (${dealTags?.length - callTagsLimit})`}
				</button>
			)}
		</div>
	);
};

export default DealTags;
