import { TooltipForMenu } from 'components/common';
import { FC, ReactNode } from 'react';
import s from './style.module.scss';
import { MenuTag } from 'shared/ui/NewTagComponent/type';

interface TagMenuProps {
	isOpen: boolean;
	handleTooltipClose: () => void;
	children: ReactNode;
	menu: MenuTag[];
}

const TagMenu: FC<TagMenuProps> = ({ isOpen, handleTooltipClose, menu, children }) => {
	return (
		<TooltipForMenu
			open={isOpen}
			popperOffset={[0, -8]}
			handleTooltipClose={handleTooltipClose}
			title={menu.map((menuItem) => {
				return (
					<div className={s.tooltipMenu} key={menuItem.id}>
						<div onClick={menuItem.onClick} style={menuItem.id === 'delete' ? { color: 'red' } : {}}>
							{menuItem.title}
						</div>
					</div>
				);
			})}
			placement="bottom"
			isInteractive={true}
		>
			{children}
		</TooltipForMenu>
	);
};

export default TagMenu;
