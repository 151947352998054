import React, { FC, memo } from 'react';
import { TagThemesTypes } from 'pages/Calls/types';
import { translate } from 'localizations';
import { LoadingButton } from '@mui/lab';
import { useToggleOpen } from 'hooks';
import cn from 'classnames';
import s from './styles.module.scss';
import TagTablet from './ui/TagTablet';
import TagSimple from './ui/TagSimple';
import ModalWindow from '../ModalWindow/ModalWindow';
import { MenuTag } from './type';
import UniversalButton from '../Buttons/UniversalButton/UniversalButton';

interface ReusableTagProps {
	id?: string | null | undefined;
	name: string | undefined;
	value: string | null;
	theme: TagThemesTypes;
	language: string;
	count?: number | null;
	additionalStyles?: React.CSSProperties;
	cursor?: string;
	isStack?: boolean; // вид стопки, обычно внутри коммуникации
	accessRights: boolean | undefined;
	settings?: boolean; // флаг включения меню при ховере
	handleClickTag: (value: string | null) => void;
	isActiveTag: boolean; // флаг активного тега по клику
	onClick?: (e: any) => void;

	deleteTag?: ((id: string) => Promise<void>) | undefined; // удаление тега
	handleRestoreTag?: (id: string) => Promise<void>; // восстановление тега
	toggleModalManual?: () => void; // закрытие модалки в восстановлении тегов
}

const NewTagComponent: FC<ReusableTagProps> = ({
	id,
	name,
	value,
	theme,
	count,
	additionalStyles,
	isStack,
	accessRights,
	settings,
	language,
	handleClickTag,
	deleteTag,
	handleRestoreTag,
	toggleModalManual,
	isActiveTag,
	onClick,
	cursor = 'default',
}) => {
	const className = `${theme}Tag`;
	const maxLength = 20;
	const tagLength = name?.length || 0;
	const valueLength = value ? value.length : 0;

	const hasTagTooltip = tagLength > maxLength;
	const hasValueTooltip = valueLength > maxLength;

	const classNameTablet = isStack ? s.tagTabletStack : s.tagTablet;

	const { toggle: handleModalToDeleteTag, isShowing: isOpenModalToDeleteTag } = useToggleOpen();
  const { toggle: handleModalToRestoreTag, isShowing: isOpenModalToRestoreTag } = useToggleOpen();
	  const textModal = translate('hiddenTagsReset', language);

	const deleteTagAsync = async () => {
		if (id) {
			await deleteTag?.(id);
			handleModalToDeleteTag();
		}
	};

	const restoreTagAsync = async () => {
		if (id) {
			await handleRestoreTag?.(id);
			handleModalToRestoreTag();
			toggleModalManual?.();
		}
	}

	const getMenu = React.useMemo(() => {
		let menu: MenuTag[] = [];
		if (deleteTag) {
			menu.push({
				id: 'delete',
				title: translate('deleteButton', language),
				onClick: () => {
					handleModalToDeleteTag();
				}
			});
		};
		if (handleRestoreTag) {
			menu.push({
				id: 'restore',
				title: translate('restoreButton', language),
				onClick: () => {
					handleModalToRestoreTag();
				}
			});
		};
		return menu;
	},[deleteTag, handleRestoreTag]);
	
	return (
		<div
			className={cn(s[className], { [s.tagMinWidth]: settings })}
			style={{ cursor: cursor, ...additionalStyles }}
			onClick={onClick ?? undefined}
		>
			{value ? (
				// вариант со значением = таблетка
				<TagTablet
					id={id}
					name={name}
					count={count}
					value={value}
					theme={theme}
					isActiveTag={isActiveTag}
					settings={settings}
					accessRights={accessRights}
					classNameTablet={classNameTablet}
					hasTagTooltip={hasTagTooltip}
					hasValueTooltip={hasValueTooltip}
					handleClickTag={handleClickTag}
					menu={getMenu}
				/>
			) : (
				// вариант обычный
				<TagSimple
					id={id}
					name={name}
					count={count}
					theme={theme}
					isActiveTag={isActiveTag}
					settings={settings}
					accessRights={accessRights}
					hasTagTooltip={hasTagTooltip}
					handleClickTag={handleClickTag}
					menu={getMenu}
				/>
			)}
			<ModalWindow
				isMWOpen={isOpenModalToDeleteTag}
				handleMWClose={handleModalToDeleteTag}
				text={translate('manualModalDelete', language)}
			>
				<div className={s.modalBtns}>
					<LoadingButton variant="contained" color="primary" type="submit" onClick={deleteTagAsync}>
						{translate('delete', language)}
					</LoadingButton>
					<LoadingButton variant="outlined" color="primary" onClick={handleModalToDeleteTag}>
						{translate('cancelButton', language)}
					</LoadingButton>
				</div>
			</ModalWindow>
			<ModalWindow
        isMWOpen={isOpenModalToRestoreTag}
        handleMWClose={handleModalToRestoreTag}
        text={textModal}
        width="600px"
				classNameTitleText={s.titleModal}
        blockFotter={
          <div className={s.buttons}>
            <UniversalButton
              buttonStyle="ghost"
              text={translate('cancelButton', language)}
              handleClick={handleModalToRestoreTag}
              testId={'restore_tag_cancel'}
            />
            <UniversalButton
              buttonStyle="filled"
              text={translate('restoreButton', language)}
              handleClick={restoreTagAsync}
              testId={'restore_tag_ok'}
            />
          </div>
        }
      >
        <div className={s.contentModal}>{translate('hiddenTagsResetQue', language)}</div>
      </ModalWindow>
		</div>
	);
};

export default memo(NewTagComponent);
