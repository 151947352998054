import React, { FC, useState } from 'react';
import { Tooltip } from 'components/common';
import classNames from 'classnames';
import s from '../styles.module.scss';
import IconsForTag from './TagWIthIcons/components/IconsForTag';
import { TagSimpleProps } from './TagSimple';

/*  
	Тег таблетка, со значением.

	есть проверка на тултип hasTagTooltip hasValueTooltip
	
	если счетчик (count) больше одного, то всплывающего меню у тега нет
*/

interface TagTabletProps extends TagSimpleProps {
	classNameTablet: string;
	hasValueTooltip: boolean;
	value: string;
}

const TagTablet: FC<TagTabletProps> = ({
	id,
	theme,
	classNameTablet,
	hasTagTooltip,
	hasValueTooltip,
	name,
	count,
	value,
	settings,
	isActiveTag,
	accessRights,
	handleClickTag,
	menu,
}) => {
	const [isHover, setIsHover] = useState(false);

	return (
		<div className={classNameTablet} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
			{hasTagTooltip ? (
				<Tooltip title={name || ''} placement="top" background="dark">
					<span className={classNames(s.tagFlex, s.name)}>
						<div className={s.ellipsis}>{name}</div>
						{count && count > 1 && <div className={s.count}>x{count}</div>}
					</span>
				</Tooltip>
			) : (
				<span className={s.name}>
					{name}
					{count && count > 1 && <div className={s.count}>x{count}</div>}
				</span>
			)}

			{count && count > 1 ? (
				<>
					{hasValueTooltip ? (
						<Tooltip title={value} placement="top" background="dark">
							<span className={classNames(s.tagFlex, s.value)}>
								<div className={s.ellipsis}>{value}</div>
							</span>
						</Tooltip>
					) : (
						<span className={s.value}>{value}</span>
					)}
				</>
			) : (
				<>
					{hasValueTooltip ? (
						<Tooltip title={value} placement="top" background="dark">
							<IconsForTag
								id={id}
								isActiveTag={isActiveTag}
								theme={theme}
								tagTablet={true}
								active={settings}
								isHover={isHover}
								accessRights={accessRights}
								handleClickTag={handleClickTag}
								menu={menu}
							>
								<span className={classNames(s.tagFlex, s.value)}>
									<div className={s.ellipsis}>{value}</div>
								</span>
							</IconsForTag>
						</Tooltip>
					) : (
						<IconsForTag
							id={id}
							isActiveTag={isActiveTag}
							theme={theme}
							isHover={isHover}
							active={settings}
							tagTablet={true}
							accessRights={accessRights}
							handleClickTag={handleClickTag}
							menu={menu}
						>
							<span className={s.value}>{value}</span>
						</IconsForTag>
					)}
				</>
			)}
		</div>
	);
};

export default React.memo(TagTablet);
