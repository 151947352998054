import { translate } from 'localizations';
import { AccessRights } from 'store/user/user.types';
import { OptionType } from 'components/common/Selects/LabelSelect/LabelSelect';
import { useMemo } from 'react';
import { useAppSelector } from './redux';

/*
		isSingleCall - действия для одного звонка или нет
		isSettingsActions - действия на странице настроек
	*/

const useCallsActions = (accessRights: AccessRights | null, isSingleCall = false, isSettingsActions = false) => {
	const language = useAppSelector((state) => state.lang.language);

	const actionsTranslations = [
		{ value: 'reset_manual', label: translate('resetManual', language) },
		{ value: 'meta_info', label: translate('metaInfo', language) },
		{ value: 'swap_channels', label: translate('swapChannels', language) },
		{ value: 'get_api_tags', label: translate('getApiTags', language) },
		{ value: 'apply_notify_rules', label: translate('applyNotifyRules', language) },
		{ value: 'apply_addressbook_tags', label: translate('applyAddressbookTags', language) },
		{ value: 'editNotificationRule', label: translate('editNotificationRule', language) },
		{ value: 'stt', label: translate('stt', language) },
		{ value: 'analyze', label: translate('applyAnalyze', language) },
		{ value: 'apply_gpt', label: translate('applyGPT', language) },
		{ value: 'tags_filter', label: translate('tagsFilter', language) },
		{
			value: 'delete',
			label: isSingleCall ? translate('deleteCall', language) : translate('deleteCalls', language),
		},
	];

	const userActionsArray = useMemo((): OptionType[] => {
		const actionsArray = [];

		if (accessRights) {
			if (accessRights.retag && isSettingsActions) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'analyze'));
			}
			if (accessRights.gpt && !isSingleCall) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'apply_gpt'));
			}
			if (accessRights.restt) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'stt'));
			}
			if (accessRights.manual_tagging && isSingleCall) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'reset_manual'));
			}
			if (isSingleCall) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'meta_info'));
			}
			if (accessRights.retag) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'swap_channels'));
				actionsArray.push(actionsTranslations.find((item) => item.value === 'get_api_tags'));
			}
			if (accessRights.notify) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'apply_notify_rules'));
			}
			if (accessRights.address_book) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'apply_addressbook_tags'));
			}
			if (accessRights.notify && isSingleCall) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'editNotificationRule'));
			}
			if (accessRights.tags && !isSingleCall) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'tags_filter'));
			}
			if (accessRights.delete_call) {
				actionsArray.push(actionsTranslations.find((item) => item.value === 'delete'));
			}
		}

		return actionsArray as OptionType[];
	}, [accessRights]);

	return { userActionsArray };
};
export default useCallsActions;
