import { FC, ReactNode } from 'react';
import EditIconSvg from 'shared/ui/icons/general/EditIcon/EditIcon';
import CloseIconSvg from 'shared/ui/icons/general/CloseIcon/CloseIcon';
import { TagThemesTypes } from 'pages/Calls/types';
import TagMenu from './TagMenu';
import s from './style.module.scss';
import { themesColors } from '../../const/consts';
import React from 'react';
import { MenuTag } from 'shared/ui/NewTagComponent/type';

interface IconsForTagProps {
	id: string | null | undefined;
	active: boolean | undefined;
	accessRights: boolean | undefined;
	theme: TagThemesTypes;
	isHover: boolean;
	children: ReactNode;
	handleClickTag: (value: string | null) => void;
	tagTablet?: boolean;
	isActiveTag: boolean; // флаг активного тега по клику
	menu: MenuTag[];
}

const IconsForTag: FC<IconsForTagProps> = ({
	id,
	theme,
	active,
	isHover,
	tagTablet,
	isActiveTag,
	accessRights,
	children,
	handleClickTag,
	menu,
}) => {
	return (
		<div className={active ? s.tagHover : ''}>
			{children}
			{active && accessRights && (
				<div className={tagTablet ? s.settingsForTablet : s.settings} style={{ opacity: (isHover || isActiveTag) ? 1 : 0 }}>
					<div
						onClick={(e) => {
							handleClickTag((isActiveTag && id)? null : id || null);
							e.stopPropagation();
						}}
					>
					<TagMenu
						isOpen={isActiveTag}
						handleTooltipClose={() => handleClickTag(null)}
						menu={menu}
					>
						<EditIconSvg color={themesColors[theme].color} bg={themesColors[theme].bg} />
					</TagMenu>
					</div>
				</div>
			)}
		</div>
	);
};

export default React.memo(IconsForTag);
