/* eslint-disable consistent-return */
import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { RootState } from '../store';
import { callsActions } from './index';
import { langSlice } from '../lang/lang.slice';
import { convertDate } from '../../utils/convertDateAndTime/convertDate';
import {
	CallActionPayload,
	GetBaseCallsDataPayload,
	GetBaseCallsDataReports,
	GetCallInfoPayload,
} from './namespaces/payloads';
import { CallActionResponse, GetBaseCallsDataReportsResponse, GetBaseCallsDataResponse } from './namespaces/responses';
import { instance } from '../../api/api.config';
import { CriteriasType } from '../search/search.types';
import { CALLS_LIMIT_ON_ON_PAGE } from './const';
import { ResponseBaseCallsDataType } from './calls.types';
import { convertCriteriasForRequest } from '../../utils/convertDataForSearchFilters/convertData';
import { getTokens } from '../../utils/tokens';

function getPageSkip(page: number): number {
	// Because the page starts with 1
	const PAGE_NUMBER_OFFSET = 1;

	return (page - PAGE_NUMBER_OFFSET) * CALLS_LIMIT_ON_ON_PAGE;
}

// TODO: fix return types

export const callAction = createAsyncThunk('calls/callAction', async (payload: CallActionPayload, thunkAPI) => {
	try {
		const { data } = await instance.post<CallActionResponse>(`call/${payload.id}/action`, payload.data);
		return data;
	} catch (error) {
		thunkAPI.dispatch(langSlice.actions.setSnackbar({ type: 'error', time: 2000, text: 'Что-то пошло не так' }));
	}
});

export const callApplyGpt = createAsyncThunk('calls/callAction', async (payload: CallActionPayload, thunkAPI) => {
	try {
		const { data } = await instance.post<CallActionResponse>(`calls/action`, payload.data);
		return data;
	} catch (error) {
		thunkAPI.dispatch(langSlice.actions.setSnackbar({ type: 'error', time: 2000, text: 'Что-то пошло не так' }));
	}
});

export const getBaseCallsData = createAsyncThunk(
	'calls/getBaseCallsData',
	async (payload: GetBaseCallsDataPayload, thunkAPI) => {
		try {
			thunkAPI.dispatch(callsActions.setLoadingCalls(true));

			const { searchHash } = queryString.parse(window.location.search);
			const state = thunkAPI.getState() as RootState;
			const requestData = convertCriteriasForRequest(state.search.calls.activeCriterias);
			const { data: callsData } = await instance.post<GetBaseCallsDataResponse>('search_calls/', requestData, {
				params: {
					start_date: convertDate(state.search.calls.date[0]),
					end_date: convertDate(state.search.calls.date[1]),
					sort: state.calls.sort.sort,
					sort_desc: state.calls.sort.sortDesc,
					return_call_info: true,
					// TODO: Igor - Вынести передачу хеша в payload
					// search_cache_key: state.calls.searchHash,
					search_cache_key: searchHash,
					skip: getPageSkip(payload.page),
					limit: CALLS_LIMIT_ON_ON_PAGE,
				},
			});

			// TODO: Igor - fix count actions calls
			thunkAPI.dispatch(callsActions.setBaseCallsData({ ...callsData }));
			thunkAPI.dispatch(callsActions.setSearchCallsHash(callsData.search_cache_key));
			thunkAPI.dispatch(callsActions.setFilterCallsHash(callsData.search_filter_hash));
			thunkAPI.dispatch(callsActions.setCallsInfo(callsData.calls));
		} catch (error) {
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({ type: 'error', time: 2000, text: 'Что-то пошло не так' }),
			);
		}
		thunkAPI.dispatch(callsActions.setLoadingCalls(false));
	},
);

export const getCallInfo = createAsyncThunk('calls/getCallInfo', async (payload: GetCallInfoPayload) => {
	try {
		const { token } = getTokens();
		const response = await instance.get(`call/${payload.id}`, {
			headers: {
				Authorization: `Bearer ${payload.token ? payload.token : token}`,
			},
		});
		return response.data;
	} catch (error) {
		console.log(error);
	}
});

export const getHash = createAsyncThunk(
	'calls/getHash',
	async (payload: { searchParams: CriteriasType[]; date?: (string | null)[] }) => {
		try {
			const { token } = getTokens();
			const { data } = await instance.post<ResponseBaseCallsDataType>(
				'search_calls/',
				payload.searchParams,

				{ headers: { Authorization: `Bearer ${token}` } },
			);
			return data.search_filter_hash;
		} catch (error) {
			console.log(`Виновен calls.slice.ts, str 130, ${error}`);
		}
	},
);

export const getBaseCallsDataReports = createAsyncThunk(
	'calls/getBaseCallsDataReports',
	async (payload: GetBaseCallsDataReports, thunkAPI) => {
		try {
			const { data: callsData } = await instance.get<GetBaseCallsDataReportsResponse>(
				`/reports/call_ids/${payload.hash}`,
				{
					params: {
						return_call_info: true,
						skip: getPageSkip(payload.page),
						limit: CALLS_LIMIT_ON_ON_PAGE,
					},
				},
			);
			thunkAPI.dispatch(callsActions.setBaseCallsData({ ...callsData }));

			thunkAPI.dispatch(callsActions.setSearchCallsHash(callsData.search_cache_key));
			thunkAPI.dispatch(callsActions.setFilterCallsHash(callsData.search_filter_hash));

			thunkAPI.dispatch(callsActions.setCallsInfo(callsData.calls));
		} catch (error) {
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({
					type: 'error',
					time: 2000,
					text: 'Something wrong',
				}),
			);
		}
	},
);

export const getTranslationWithLanguage = createAsyncThunk(
	'calls/getTranslationWithLanguage',
	async (payload: { callId: string; languageCode: string }, thunkAPI) => {
		try {
			const { token } = getTokens();
			const { data } = await instance.get(`call/${payload.callId}/translation?language=${payload.languageCode}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			thunkAPI.dispatch(callsActions.setCallTranslated(data));
			return data;
		} catch (error) {
			console.log(error);
		}
	},
);

export const getSearchTagsSuggestions = createAsyncThunk(
	'calls/getSearchTagsSuggestions',
	async (payload: string, thunkAPI) => {
		try {
			const response = await instance.get<GetBaseCallsDataReportsResponse>(
				`/search_tags_suggest?filter=${payload}`,
			);
			return await response;
		} catch (error) {
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({
					type: 'error',
					time: 2000,
					text: 'Something is wrong',
				}),
			);
		}
	},
);

export const getSearchChecklistsSuggestions = createAsyncThunk(
	'calls/getSearchChecklistsSuggestions',
	async (payload: string, thunkAPI) => {
		try {
			const response = await instance.get<GetBaseCallsDataReportsResponse>(
				`/search_checklists_suggest?filter=${payload}`,
			);
			return await response;
		} catch (error) {
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({
					type: 'error',
					time: 2000,
					text: 'Something is wrong',
				}),
			);
		}
	},
);

export const getSearchCommentsSuggestions = createAsyncThunk(
	'calls/getSearchCommentsSuggestions',
	async (payload: string, thunkAPI) => {
		try {
			const response = await instance.get<GetBaseCallsDataReportsResponse>(
				`/search_comments_suggest?filter_str=${payload}`,
			);
			return await response;
		} catch (error) {
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({
					type: 'error',
					time: 2000,
					text: 'Something is wrong',
				}),
			);
		}
	},
);

export const getTagNames = createAsyncThunk('user/getTagNames', async (_, thunkAPI) => {
	try {
		const response = await instance.get('tag_names');
		thunkAPI.dispatch(callsActions.setTagNames(response.data));
		return response.data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});
